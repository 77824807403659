import { WeekActiveHeader } from '@/components/week/week-active-header';
import { WeekHeaderEntry } from '@/components/week/week-header-entry';
import { useEffect, useState } from 'react';
import { OrderBill, Week } from '@/backend/api/api-service';

// TODO work with onChanged

interface Props {
  weeks: Week[];
  currentWeekIndex: number;
  setCurrentWeekIndex: (i: number) => void;
  pauseWeek: () => void;
  resumeWeek: () => void;
  onIClick: () => void;
  isConfirming: boolean;
  localNumberOfMeals: number;
  localOrderBill: OrderBill | null;
  confirm: () => void;
}

export function WeekHeader({
  weeks, currentWeekIndex, setCurrentWeekIndex, pauseWeek, resumeWeek, onIClick, isConfirming,
  localNumberOfMeals, localOrderBill, confirm,
}: Props) {
  const [currentWeek, setCurrentWeek] = useState<Week>(weeks[currentWeekIndex]);

  useEffect(() => {
    setCurrentWeek(weeks[currentWeekIndex]);
  }, [currentWeekIndex, weeks]);

  return (
    <>
      <div className="flex flex-col bg-stone-100 sm:sticky sm:top-20 sm:z-20 py-2">
        <div className="flex gap-2 overflow-x-scroll no-scrollbar">
          { weeks.map((week, index) => (
            <WeekHeaderEntry
              current={currentWeekIndex === index}
              setCurrent={() => setCurrentWeekIndex(index)}
              week={week}
              onPause={pauseWeek}
              onResume={resumeWeek}
              key={week.startDate}
              onIClick={onIClick}
              disabled={currentWeekIndex !== index && isConfirming}
              isConfirming={isConfirming}
              localNumberOfMeals={localNumberOfMeals}
              localOrderBill={localOrderBill}
              confirm={confirm}
            />
          ))}
        </div>
      </div>
      <div className="sticky top-0 z-20 sm:hidden pb-2 bg-stone-100">
        <div className="px-2 py-2 flex bg-mk items-center rounded-xl font-semibold font-title text-2xl ">
          <WeekActiveHeader
            week={currentWeek}
            order={currentWeek.order}
            subscription={currentWeek.subscription}
            onPause={pauseWeek}
            onResume={resumeWeek}
            onIClick={onIClick}
            isConfirming={isConfirming}
            localNumberOfMeals={localNumberOfMeals}
            localOrderBill={localOrderBill}
            confirm={confirm}
          />
        </div>
      </div>
    </>
  );
}
