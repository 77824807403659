import classNames from 'classnames';
import { WeekProduct } from '@/backend/api/api-service';
import { Product } from '@/components/product/product';

function ProductViewFooter({ userQuantity }: { userQuantity: number }) {
  return (
    <div className={classNames(
      'h-16 mt-3 rounded-b-xl text-center flex justify-center items-center',
      `${userQuantity > 0 ? 'bg-mk' : 'bg-mk-light'}`,
    )}
    >
      <div className="text-2xl font-bold font-title p-1">
        {userQuantity}
        {' '}
        bestellt
      </div>
    </div>
  );
}

export interface ProductViewProps {
  item: WeekProduct,
  userQuantity: number;
  disabled: boolean | undefined;
}

export function ProductView({
  item, userQuantity, disabled,
}: ProductViewProps) {
  return (
    <Product item={item} disabled={disabled}>
      <ProductViewFooter userQuantity={userQuantity} />
    </Product>
  );
}
