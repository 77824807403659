import { BraintreePayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  BackendError, BraintreeClient, fetchBraintreeClient, savePaypal, SavePaypalDto,
} from '@/backend/api/api-service';

interface Props {
  onDone: () => void;
}

function InnerPaypalButton({ onDone }: Props) {
  const queryClient = useQueryClient();
  const savePaypalMutation = useMutation<{}, BackendError, SavePaypalDto>(savePaypal, {
    onSuccess() {
      queryClient.invalidateQueries(['settings']);
      onDone();
    },
  });

  return (
    <div className="relative z-10">
      <BraintreePayPalButtons
        style={{ color: 'silver', shape: 'pill', height: 48 }}
        disabled={false}
        fundingSource="paypal"
        createBillingAgreement={(data, actions) => actions.braintree
          .createPayment({
            flow: 'vault',

          })}
        onApprove={(data, actions) => actions.braintree
          .tokenizePayment(data)
          .then(({ nonce }) => {
            savePaypalMutation.mutate({ nonce });
          })}
      />
    </div>
  );
}

export function PaypalButton({ onDone }: Props) {
  const braintreeClientQuery = useQuery<BraintreeClient, BackendError>(
    ['braintree-client'],
    fetchBraintreeClient,
  );

  return (braintreeClientQuery.data
    ? (
      <PayPalScriptProvider
        options={{
          'client-id': braintreeClientQuery.data.paypalClientId,
          'data-client-token': braintreeClientQuery.data.clientToken,
          vault: true,
          intent: 'tokenize',
        }}
      >
        <InnerPaypalButton onDone={onDone} />
      </PayPalScriptProvider>
    )
    : <div className="h-12 invisible">x</div>
  );
}
