import { ErrorBox } from '@/components/error/error-box';

export function QueryError() {
  return (
    <ErrorBox>
      <div className="mb-3 text-red-700 font-bold text-center">
        <p>Es ist ein technisches Problem aufgetreten.</p>
        <p>Bitte versuche es später noch einmal!</p>
      </div>
    </ErrorBox>
  );
}
