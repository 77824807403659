import {
  BackendError,
  fetchOrderBill,
  OrderBill,
  updateOrder,
  UpdateOrderDto,
  WeekOrder,
} from '@/backend/api/api-service';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

function numberOfMeals(items: Map<string, number>) {
  if (items) {
    return Array.from(items.entries()).reduce((acc, [, quantity]) => acc + quantity, 0);
  }
  return 0;
}

export function useOrderManagement() {
  const queryClient = useQueryClient();

  const [serverState, setServerState] = useState<{
    startDate: string, confirmedNumberOfMeals: number, hasPaymentMethod: boolean, weekId: string, numberOfMeals: number;
  } | null>(null);

  const [localOrder, setLocalOrder] = useState<Map<string, number> | null>(null);
  const [localNumberOfMeals1, setLocalNumberOfMeals1] = useState(0);
  const [localOrderBill, setLocalOrderBill] = useState<OrderBill | null>(null);

  useEffect(() => {
    if (localOrder) {
      setLocalNumberOfMeals1(numberOfMeals(localOrder));
    }
  }, [localOrder]);

  const [needsConfirmation, setNeedsConfirmation] = useState(false);

  const [needsPaymentMethod, setNeedsPaymentMethod] = useState(false);

  useEffect(() => {
    if (serverState) {
      setNeedsPaymentMethod(needsConfirmation && !serverState.hasPaymentMethod && !localOrderBill?.isPayroll);
    }
  }, [needsConfirmation, serverState, localOrderBill?.isPayroll]);

  const { mutate } = useMutation<WeekOrder, BackendError, UpdateOrderDto>(updateOrder, {});

  useEffect(() => {
    const x = async () => {
      if (localOrder) {
        const localNumberOfMeals = numberOfMeals(localOrder);
        if (serverState) {
          const localBill = await queryClient.fetchQuery<OrderBill | null>([
            'order-bill', localNumberOfMeals, serverState.startDate,
          ], () => fetchOrderBill({ startDate: serverState.startDate, numberOfMeals: localNumberOfMeals }));
          setLocalOrderBill(localBill);

          const nowNeedsConfirmation = localNumberOfMeals > serverState.confirmedNumberOfMeals
              && (localBill?.additional.totalCent || 0) > 0;
          setNeedsConfirmation(nowNeedsConfirmation);

          if (!nowNeedsConfirmation) {
            mutate(
              {
                weekId: serverState.weekId,
                dto: {
                  items: Array.from(localOrder.entries()).map((entry) => (
                    { productId: entry[0], userQuantity: entry[1] })),
                  source: 'user',
                  confirmedNumberOfMeals: Math.max((serverState.confirmedNumberOfMeals), localNumberOfMeals),
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries(['weeks']);
                },
              },
            );
          }
        }
      }
    };

    x();
  }, [queryClient, localOrder, serverState, mutate]);

  useEffect(() => {
    setLocalOrder(null);
    setLocalOrderBill(null);
    setLocalNumberOfMeals1(serverState?.numberOfMeals || 0);
    setNeedsConfirmation(false);
    setNeedsPaymentMethod(false);
  }, [serverState]);

  const confirm = useCallback(() => {
    if (serverState && localOrder) {
      const localNumberOfMeals = numberOfMeals(localOrder);
      mutate(
        {
          weekId: serverState.weekId,
          dto: {
            items: Array.from(localOrder.entries()).map((entry) => (
              { productId: entry[0], userQuantity: entry[1] })),
            source: 'user',
            confirmedNumberOfMeals: Math.max((serverState.confirmedNumberOfMeals), localNumberOfMeals),
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['weeks']);
          },
          onError: () => {
          },
        },
      );
    }
  }, [localOrder, mutate, queryClient, serverState]);

  return {
    setServerOrder: setServerState,
    updateLocalOrder: setLocalOrder,
    serverOrder: serverState,
    localOrder,
    stringLocalOrder: localOrder,
    needsConfirmation,
    needsPaymentMethod,
    localNumberOfMeals1,
    confirm,
    localOrderBill,
  };
}
