import { Week } from '@/backend/api/api-service';
import { ProductNoOrder } from '@/components/product/product-no-order';

interface Props {
  week: Week | null;
}

export function CancelledWeekOrder({
  week,
}: Props) {
  return (week
      && (
      <section>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3 mb-2">
          { week.products.map((product) => (
            <ProductNoOrder
              item={product}
              disabled={false}
              key={product.productId}
            />
          ))}
        </div>
      </section>
      )
  );
}
