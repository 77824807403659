import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import { WeekProduct } from '@/backend/api/api-service';
import { PropsWithChildren } from 'react';
import { translateProperties } from '@/utils/product';

export interface ProductProps {
  item: WeekProduct,
  disabled: boolean | undefined;
}

export function Product({
  item, disabled, children,
}: PropsWithChildren<ProductProps>) {
  return (
    <div className={`flex flex-col bg-white rounded-xl w-full max-w-[600px] mx-auto ${disabled ? 'opacity-50' : ''}`}>
      <div className="relative">
        <Image
          className="rounded-t-xl"
          src={`${item.imageUrl}?fm=webp&fit=crop&w=600&h=600`}
          alt={item.title}
          width={600}
          height={600}
        />
      </div>
      <div className="text-2xl font-semibold text-stone-900 font-title px-3 pt-3">{item.title}</div>
      <div className="px-3 pt-2 flex-1 text-stone-700">{item.description}</div>

      <div className="flex items-center justify-between px-3 pt-2">
        <div className="text-sm text-stone-700 pr-3">
          { translateProperties(item.properties).join(' | ')}
        </div>
        <a href={item.cardUrl} target="_blank" rel="noreferrer">
          <div className="text-stone-700">
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ fontSize: 'xx-large' }}
            />
          </div>
        </a>
      </div>
      {children}
    </div>
  );
}
