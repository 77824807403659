import Link from 'next/link';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export function ErrorBox({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-col h-screen w-screen absolute inset-0 top-0 -z-10">
      <div className="flex-grow flex flex-col items-center justify-center gap-2">
        <div className="bg-white p-4 rounded-xl flex flex-col gap-2 items-center">
          {children}
          <Link href="/wochen" className="text-stone-700 hover:text-stone-900 underline">Mein MARKTKOST</Link>
          <a
            href="mailto:hallo@marktkost.de"
            target="_blank"
            className={classNames(
              'block mt-4 bg-white w-fit border border-mk-900 px-6 py-1 rounded-xl',
              'text-sm text-mk-900 font-title font-semibold uppercase',
            )}
            rel="noreferrer"
          >
            Hilfe Anfordern
          </a>
        </div>
      </div>
    </div>
  );
}
