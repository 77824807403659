import { UserAppLayout } from '@/components/layout';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  BackendError,
  fetchWeeks,
  pauseOrder, PauseOrderParams,
  resumeOrder,
  ResumeOrderParams,
  Week,
  WeekOrder,
} from '@/backend/api/api-service';
import { WeekHeader } from '@/components/week/week-header';
import { WeekOrderView } from '@/components/week/week-order-view';
import { Dialog } from '@/components/base/dialog';
import { formatGermanDate } from '@/utils/datetime';
import { OrderBillView2 } from '@/components/bill/order-bill-2';
import { GlobalContext } from '@/components/global-context-provider';
import { WithBackendQueryHandling } from '@/components/error/with-backend-query-handling';
import { SettingsPaymentDialog } from '@/components/settings/settings-payment-dialog';
import { useOrderManagement } from '@/components/week/use-order-management';

export default function Wochen() {
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [currentWeek, setCurrentWeek] = useState<Week | null>(null);
  const [orderBillDialogOpen, setOrderBillDialogOpen] = useState(false);
  const [localOrder, setLocalOrder] = useState<Map<string, number>>(
    new Map(currentWeek?.order?.items.map((item) => [item.productId, item.userQuantity]) || []),
  );

  const { setDisableNavigation } = useContext(GlobalContext);

  const orderManagement = useOrderManagement();
  const {
    setServerOrder,
    updateLocalOrder,
    needsConfirmation, needsPaymentMethod,
    localNumberOfMeals1, localOrderBill,
    confirm,
  } = orderManagement;

  useEffect(() => {
    setDisableNavigation(needsConfirmation);
  }, [needsConfirmation, setDisableNavigation]);

  const weeksQuery = useQuery<Week[], BackendError>(
    ['weeks'],
    () => fetchWeeks(),
  );

  useEffect(() => {
    if (weeksQuery.data && currentWeekIndex < weeksQuery.data.length) {
      setCurrentWeek(weeksQuery.data[currentWeekIndex]);
      setServerOrder(weeksQuery.data[currentWeekIndex].order
        ? {
          startDate: weeksQuery.data[currentWeekIndex].startDate,
          confirmedNumberOfMeals: weeksQuery.data[currentWeekIndex].order?.confirmedNumberOfMeals || 0,
          hasPaymentMethod: !!weeksQuery.data[currentWeekIndex].order?.hasPaymentMethod,
          weekId: weeksQuery.data[currentWeekIndex].id,
          numberOfMeals: weeksQuery.data[currentWeekIndex].order?.numberOfProducts || 0,
        } : null);
    }
  }, [weeksQuery.data, currentWeekIndex, setServerOrder]);

  const queryClient = useQueryClient();

  const resumeMutation = useMutation<WeekOrder, BackendError, ResumeOrderParams>(resumeOrder, {});
  const pauseMutation = useMutation<WeekOrder, BackendError, PauseOrderParams>(pauseOrder, {});

  useEffect(() => {
    setLocalOrder(
      new Map(currentWeek?.order?.items.map((item) => [item.productId, item.userQuantity]) || []),
    );
  }, [currentWeek]);

  const updateLocalAndServerOrder = (newLocalOrder: Map<string, number>) => {
    updateLocalOrder(newLocalOrder);
  };

  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

  const confirmAdditional = () => {
    if (needsPaymentMethod) {
      setPaymentDialogOpen(true);
    } else {
      confirm();
    }
  };

  const confirmAdditionalAndClose = () => {
    confirm();
    setPaymentDialogOpen(false);
  };

  const pauseWeek = () => {
    if (currentWeek) {
      pauseMutation.mutate({ weekId: currentWeek.id }, {
        onSuccess: () => {
          queryClient.invalidateQueries(['weeks']);
        },
      });
    }
  };

  const resumeWeek = () => {
    if (currentWeek) {
      resumeMutation.mutate({ weekId: currentWeek.id }, {
        onSuccess: () => {
          queryClient.invalidateQueries(['weeks']);
        },
      });
    }
  };

  return (
    <WithBackendQueryHandling query={weeksQuery}>
      <div className="z-100">
        <WeekHeader
          weeks={weeksQuery.data || []}
          currentWeekIndex={currentWeekIndex}
          setCurrentWeekIndex={setCurrentWeekIndex}
          pauseWeek={pauseWeek}
          resumeWeek={resumeWeek}
          onIClick={() => {
            setOrderBillDialogOpen(true);
          }}
          isConfirming={needsConfirmation}
          localNumberOfMeals={localNumberOfMeals1}
          localOrderBill={localOrderBill}
          confirm={confirmAdditional}
        />
        <WeekOrderView week={currentWeek} order={localOrder} updateOrder={updateLocalAndServerOrder} />
        {currentWeek?.order?.bill && (
        <Dialog
          isOpen={orderBillDialogOpen}
          title={`Bestellung für den ${formatGermanDate(currentWeek?.startDate || '')}`}
          onCancel={() => setOrderBillDialogOpen(false)}
          onSave={() => {
            confirmAdditional();
            setOrderBillDialogOpen(false);
          }}
          saveLabel="Kostenpflichtig Bestellen"
          testId="order-bill-dialog"
        >
          <div className="p-3"><OrderBillView2 bill={localOrderBill} /></div>
        </Dialog>
        )}
      </div>
      <SettingsPaymentDialog
        isOpen={paymentDialogOpen}
        setIsOpen={setPaymentDialogOpen}
        paymentMethod={undefined}
        onCancel={() => setPaymentDialogOpen(false)}
        onDone={confirmAdditionalAndClose}
        redirectQuery="wochen"
      />
    </WithBackendQueryHandling>
  );
}

Wochen.layout = UserAppLayout;
