import { OrderBill } from '@/backend/api/api-service';
import { formatEuroCents } from '@/utils/euro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

export function WeekHeaderConfirm({ bill, onIClick, confirm }: {
  bill: OrderBill | null, onIClick: () => void, confirm: () => void }) {
  return (
    <div className="w-fit text-base flex flex-col gap-2">
      <div>
        <div className=" text-center px-3 pt-1 pb-2 rounded-xl text-xl">
          {formatEuroCents(bill?.additional.totalCent || 0)}
          {' '}
          zusätzlich
          <button
            type="button"
            className="inline-block pl-6 text-2xl hover:scale-105"
            onClick={() => { onIClick(); }}
          >
            <FontAwesomeIcon icon={faCircleInfo} />
          </button>
        </div>
      </div>
      <div>
        <button
          type="button"
          className={classNames(
            'bg-white px-3 py-1 rounded-xl',
            'text-lg text-stone-900 font-title font-semibold uppercase shadow-md hover:bg-mk-900',
          )}
          onClick={confirm}
        >
          Kostenpflichtig Bestellen
        </button>
      </div>
    </div>
  );
}
