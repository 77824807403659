import { loadStripe } from '@stripe/stripe-js';
import { useQuery } from 'react-query';
import {
  BackendError, CreateStripeSetupIntentDto,
  StripeSetupIntent,
} from '@/backend/api/api-service';
import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { Dialog } from '@/components/base/dialog';
import classNames from 'classnames';
import { Button } from '@/components/base/button';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '');

interface StripeButtonInnerProps {
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,
  dialogTitle: string;
  returnPath: string;
}

function StripeButtonInner({
  isOpen, setIsOpen, dialogTitle, returnPath,
}: StripeButtonInnerProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);

  useEffect(() => {
    setSaveDisabled(!stripe || !elements);
  }, [stripe, elements]);

  const handleSubmit = async () => {
    // // We don't want to let default form submission happen here,
    // // which would refresh the page.
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.NEXT_PUBLIC_APP_URL}/${returnPath}`,
      },
    });

    if (error) {
      // dialog stays open and errors are shown
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setIsOpen(false);
    }

    return null;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Dialog
        isOpen={isOpen}
        title={dialogTitle}
        onCancel={() => setIsOpen(false)}
        onSave={handleSubmit}
        disableSave={saveDisabled}
        testId="stripe-dialog"
      >
        <div className="p-3">
          {/* TODO prefill as much as possible */}
          <PaymentElement />
        </div>
      </Dialog>
    </form>
  );
}

interface StripeButtonProps {
  stripePaymentMethod: string;
  buttonLabel: string;
  dialogTitle: string;
  returnPath: string;
  fetchSetupIntent: {
    (dto: CreateStripeSetupIntentDto): Promise<StripeSetupIntent>;
    key:(stripePaymentMethod: string) => string[];
  };
}

export function StripeButton({
  stripePaymentMethod, buttonLabel, dialogTitle, returnPath, fetchSetupIntent,
}: StripeButtonProps) {
  const [showDialog, setShowDialog] = useState(false);
  const [secret, setSecret] = useState<string | undefined>(undefined);

  const clientSecret = useQuery<StripeSetupIntent, BackendError>(
    fetchSetupIntent.key(stripePaymentMethod),
    () => fetchSetupIntent({ stripePaymentMethod }),
    {
      enabled: !secret,
      onSuccess: (data) => {
        setSecret(data.clientSecret);
      },
    },
  );

  return clientSecret.data
    ? (
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: clientSecret.data.clientSecret, locale: 'de' }}
        key={clientSecret.data.clientSecret}
      >
        <div>
          { stripePaymentMethod === 'card'
            ? <Button label={buttonLabel} onClick={() => setShowDialog(true)} wFull /> : (
              <button
                type="button"
                className={classNames(
                  'w-full border border-stone-500 px-6 py-3 rounded-xl',
                  'text-md text-stone-500 font-title font-semibold uppercase',
                )}
                onClick={() => setShowDialog(true)}
              >
                {buttonLabel}
              </button>
            )}
          <StripeButtonInner
            isOpen={showDialog}
            setIsOpen={setShowDialog}
            dialogTitle={dialogTitle}
            returnPath={returnPath}
          />
        </div>
      </Elements>
    ) : (<div />);
}
