import { Week } from '@/backend/api/api-service';
import { ActiveWeekOrder } from '@/components/week/active-week-order';
import { InactiveWeekOrder } from '@/components/week/inactive-week-order';
import { CancelledWeekOrder } from '@/components/week/cancelled-week-order';

interface Props {
  week: Week | null;
  order: Map<string, number>;
  updateOrder: (order: Map<string, number>) => void;
}

export function WeekOrderView({
  week, order, updateOrder,
}: Props) {
  if (!week?.order) { return <CancelledWeekOrder week={week} />; }

  if (week?.order.status === 'blocked') {
    return <CancelledWeekOrder week={week} />;
  }

  if (week?.order.status === 'open' || week?.order.status === 'paused') {
    return <ActiveWeekOrder week={week} order={order} updateOrder={updateOrder} />;
  }

  return <InactiveWeekOrder week={week} order={order} />;
}
