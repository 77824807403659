import { PropsWithChildren } from 'react';

import { UseQueryResult } from 'react-query';
import { Loading } from '@/components/error/loading';
import { BackendError } from '@/backend/api/api-service';
import { QueryError } from '@/components/error/query-error';

interface Props<T> {
  query: UseQueryResult<T, BackendError>
}

export function WithBackendQueryHandling<T>({
  query: {
    data, isLoading, isError,
  },
  children,
}: PropsWithChildren<Props<T>>) {
  if (data) {
    return <div>{children}</div>;
  }

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (isError) {
    return <QueryError />;
    // TODO sentry or something
  }

  return <div />;
}
