import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface Props {
  isOpen: boolean;
  testId: string;
}

export function Modal({ isOpen, testId, children }: PropsWithChildren<Props>) {
  if (isOpen) {
    return (
      <div className={classNames('text-base', 'relative z-90')}>
        <div className="fixed inset-0 bg-stone-200 bg-opacity-75 " />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="w-full h-screen flex items-center justify-center">
            <div
              className={classNames(
                'bg-white w-full h-full sm:w-10/12 md:w-8/12 max-w-2xl sm:h-fit',
                'sm:rounded-2xl flex flex-col z-90',
              )}
              data-testid={testId}
            >
              <div className="bg-white sm:rounded-2xl">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
