import { WeekProduct } from '@/backend/api/api-service';
import { Product } from '@/components/product/product';

function ProductNoOrderFooter() {
  return (
    <div className="h-4 mt-3 rounded-b-xl text-center flex justify-center items-center bg-mk" />
  );
}

export interface ProductNoOrderProps {
  item: WeekProduct,
  disabled: boolean | undefined;
}

export function ProductNoOrder({
  item, disabled,
}: ProductNoOrderProps) {
  return (
    <Product item={item} disabled={disabled}><ProductNoOrderFooter /></Product>
  );
}
