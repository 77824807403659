import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import { Product } from '@/components/product/product';
import { WeekProduct } from '@/backend/api/api-service';

interface ProductOrderFooterProps {
  userQuantity: number;
  setUserQuantity: (userQuantity: number) => void;
  disabled: boolean | undefined;
}

function ProductOrderFooter({ userQuantity, setUserQuantity, disabled }: ProductOrderFooterProps) {
  return (
    <div className={classNames(
      'h-16 mt-3 rounded-b-xl text-center flex justify-between items-center',
      `${userQuantity > 0 ? 'bg-mk' : 'bg-mk-light'}`,
    )}
    >
      <button
        type="button"
        className={classNames('pl-10 pr-5 rounded-full flex items-center justify-center text-xl', {
          'hover:scale-110': !disabled && userQuantity > 0,
        })}
        onClick={(event) => {
          event.stopPropagation();
          if (!disabled && userQuantity > 0) {
            setUserQuantity(userQuantity - 1);
          }
        }}
        data-testid="product-order-decrease"
      >
        <FontAwesomeIcon icon={faAnglesDown} />
      </button>
      <div className="text-2xl font-bold font-title p-1">
        {userQuantity}
        {' '}
        ausgewählt
      </div>
      <button
        type="button"
        className={classNames('pl-5 pr-10 rounded-full flex items-center justify-center text-xl', {
          'hover:scale-110': !disabled,
        })}
        onClick={(event) => {
          event.stopPropagation();
          if (!disabled) {
            setUserQuantity(userQuantity + 1);
          }
        }}
        data-testid="product-order-increase"
      >
        <FontAwesomeIcon icon={faAnglesUp} />
      </button>
    </div>
  );
}

export interface ProductOrderProps {
  item: WeekProduct,
  userQuantity: number;
  setUserQuantity: (userQuantity: number) => void;
  disabled: boolean | undefined;
}

export function ProductOrder({
  item, userQuantity, setUserQuantity, disabled,
}: ProductOrderProps) {
  return (
    <Product item={item} disabled={disabled}>
      <ProductOrderFooter userQuantity={userQuantity} setUserQuantity={setUserQuantity} disabled={disabled} />
    </Product>
  );
}
