export function translateProperties(properties: string[]): string[] {
  return properties.map((property) => {
    switch (property) {
      case 'coldConsumption':
        return 'kalter Verzehr';
      case 'light':
        return 'leichter Genuss';
      case 'protein':
        return 'Proteinquelle';
      case 'highProtein':
        return 'proteinreich';
      case 'lowCarb':
        return 'lower Carb';
      case 'glutenFree':
        return 'glutenfrei';
      case 'lactoseFree':
        return 'laktosefrei';
      case 'hotS':
        return 'leicht scharf';
      case 'hotM':
        return 'mittelscharf';
      case 'hotL':
        return 'sehr scharf';
      case 'vegan':
        return 'vegan';
      case 'vegetarian':
        return 'vegetarisch';
      default:
        return property;
    }
  });
}
