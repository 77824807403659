import { Modal } from '@/components/base/modal';
import { Button } from '@/components/base/button';
import { PropsWithChildren } from 'react';

interface Props {
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onSave: () => void;
  cancelLabel?: string; // TODO understand defaultProps
  saveLabel?: string;
  disableSave?: boolean;
  testId: string;
  extraHref?: string
  extraLabel?: string;
}

export function Dialog({
  isOpen, title, onCancel, onSave, cancelLabel, saveLabel, children, disableSave, testId, extraHref, extraLabel,
}: PropsWithChildren<Props>) {
  return (
    <Modal isOpen={isOpen} testId={testId}>
      <div className="text-lg font-title uppercase tracking-wide font-semibold bg-mk sm:rounded-t-2xl p-3">{title}</div>
      {children}
      <div className="flex flex-col-reverse md:flex-row gap-2">
        <div className="flex justify-center p-3">
          {extraHref && (
          <a
            href={extraHref}
            target="_blank"
            className="border border-mk-900 px-6 py-3 rounded-xl text-2xl text-mk-900 font-title"
            rel="noreferrer"
          >
            {extraLabel}
          </a>
          )}
        </div>
        <div className="flex flex-grow justify-around md:justify-end gap-2 p-3">
          <div>
            <button
              type="button"
              className="border border-mk-900 px-6 py-3 rounded-xl text-2xl text-mk-900 font-title"
              onClick={onCancel}
            >
              {cancelLabel}
            </button>
          </div>
          <Button label={saveLabel || Dialog.defaultProps.saveLabel} onClick={onSave} disabled={disableSave} />
        </div>
      </div>
    </Modal>
  );
}

Dialog.defaultProps = {
  cancelLabel: 'Abbrechen',
  saveLabel: 'Bestätigen',
  disableSave: false,
  extraHref: undefined,
  extraLabel: undefined,
};
