import classNames from 'classnames';
import { DateWithoutYear } from '@/components/datetime/date-without-year';
import { IsoWeek } from '@/components/datetime/iso-week';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePause } from '@fortawesome/free-solid-svg-icons';
import { WeekActiveHeader } from '@/components/week/week-active-header';
import { OrderBill, Week } from '@/backend/api/api-service';

export interface WeekHeaderEntryProps {
  week: Week;
  current: boolean;
  disabled: boolean;
  setCurrent: () => void;
  onPause: () => void;
  onResume: () => void;
  onIClick: () => void;
  isConfirming: boolean;
  localNumberOfMeals: number;
  localOrderBill: OrderBill | null;
  confirm: () => void;
}

export function WeekHeaderEntry({
  current, setCurrent, week, onPause, onResume, onIClick, disabled, isConfirming, localNumberOfMeals,
  localOrderBill, confirm,
}: WeekHeaderEntryProps) {
  return (
    <button
      type="button"
      className={classNames(
        'w-20 shrink-0 p-3 flex items-center text-xl rounded-xl cursor-pointer relative font-title font-semibold',
        {
          'bg-mk': current,
          'sm:flex-1': current,
          'xl:basis-1/2': current,
          'xl:flex-initial': current,
          'bg-white': !current,
        },
      )}
      onClick={() => { if (!disabled) setCurrent(); }}
    >
      <div className={classNames(
        { hidden: !disabled },
        'absolute top-0 left-0 w-full h-full bg-stone-200 bg-opacity-75 p-3 w-20 rounded-xl cursor-default ',
      )}
      />
      <div className="flex flex-col items-start">
        <div>
          <DateWithoutYear date={week.startDate} />
        </div>
        <div>
          KW
          {' '}
          <IsoWeek date={week.startDate} />
        </div>
      </div>
      {current && (
      <div className="hidden sm:block flex flex-1">
        <WeekActiveHeader
          week={week}
          order={week.order}
          subscription={week.subscription}
          onPause={onPause}
          onResume={onResume}
          onIClick={() => { onIClick(); }}
          isConfirming={isConfirming}
          localNumberOfMeals={localNumberOfMeals}
          localOrderBill={localOrderBill}
          confirm={confirm}
        />
      </div>
      )}
      { week.order && (week.order.status === 'paused' || week.order.status.startsWith('blocked'))
            && (
            <div className={`absolute -top-2 -right-1 ${current ? 'text-black' : 'text-mk'}`}>
              <FontAwesomeIcon icon={faCirclePause} style={{ fontSize: 'large' }} />
            </div>
            )}
    </button>
  );
}
