import { OrderBill } from '@/backend/api/api-service';
import classNames from 'classnames';
import { formatEuroCents } from '@/utils/euro';
import { formatGermanDate } from '@/utils/datetime';

// TODO NEXT reintroduce deleted stories
export function OrderBillView2({ bill }: { bill: OrderBill | null }) {
  if (bill) {
    return (
      <>
        <div className="hidden sm:block">
          <div className={classNames(
            'font-normal font-sans text-base',
            'grid grid-cols-3 grid-cols-[max-content_max-content_max-content_max-content] items-stretch',
            'border border-stone-200 rounded-xl shadow-sm p-3 bg-white text-stone-900',
          )}
          >
            <div className="mb-1" />
            <div className={classNames(
              'mb-1 text-right mr-4',
              'font-title uppercase tracking-wide text-stone-500 text-sm',
            )}
            >
              Wöchentlich
            </div>
            <div className={classNames(
              'mb-1 col-span-2 text-left pl-2',
              'font-title uppercase tracking-wide text-stone-500 text-sm',
            )}
            >
              {formatGermanDate(bill.startDate)}
            </div>

            <div className="text-right mr-1">
              {bill.subscription.numberOfMeals}
              {' + '}
              {bill.additional.numberOfMeals}
              {' '}
              Gerichte à
              {' '}
              {formatEuroCents(bill.pricePerMealCent)}
            </div>
            <div className="text-right mr-4">{formatEuroCents(bill.subscription.mealPriceTotalCent)}</div>
            <div className="text-right border-r-8 border-transparent">
              {formatEuroCents(bill.additional.mealPriceTotalCent)}
            </div>
            <div />

            {bill.total.discountTotalCent > 0
                  && (
                  <>
                    <div className="text-right mr-1">MARKTKOST Rabatt</div>
                    <div className="text-right mr-4">
                      {formatEuroCents(-bill.subscription.discountTotalCent)}
                    </div>
                    <div className="text-right border-r-8 border-transparent">
                      {formatEuroCents(-bill.additional.discountTotalCent)}
                    </div>
                    <div />
                  </>
                  )}
            {bill.total.sponsoringTotalCent > 0
                  && (
                  <>
                    <div className="text-right mr-1">
                      Arbeitgeberzuschuss
                    </div>
                    <div className="text-right mr-4">
                      {formatEuroCents(-bill.subscription.sponsoringTotalCent)}
                    </div>
                    <div className="text-right border-r-8 border-transparent">
                      {formatEuroCents(-bill.additional.sponsoringTotalCent)}
                    </div>
                    <div />
                  </>
                  )}

            <div className="col-span-4 border-t h-1 border-stone-500 border-opacity-30" />

            <div className="text-right mr-1 mb-1" />
            <div className={classNames('text-right mr-4 mb-1')}>
              {formatEuroCents(bill.subscription.totalCent)}
            </div>
            <div className={classNames(
              'text-right font-bold bg-mk-light pl-2 mb-1',
              'rounded-l-lg border-r-8 border-mk-light',
            )}
            >
              {formatEuroCents(bill.additional.totalCent)}
            </div>
            <div className="font-bold bg-mk-light rounded-r-lg pr-2 mb-1">
              zusätzlich
            </div>

            <div className="text-right mr-1">
              gesamt
            </div>
            <div />
            <div className="text-right border-r-8 border-transparent">
              {formatEuroCents(bill.total.totalCent)}
            </div>
            <div>
              <span className="text-xs">
                inkl. 7% MwSt.
              </span>
            </div>

          </div>
        </div>

        {/* portrait version */}

        <div className="sm:hidden w-full">
          <div className={classNames(
            'font-normal font-sans text-base',
            'grid grid-cols-[max-content_max-content_max-content] items-stretch',
            'border border-stone-200 rounded-xl shadow-sm p-3 bg-white text-stone-900',
          )}
          >
            <div className={classNames(
              'col-span-3',
              'mb-1 mr-4',
              'font-title uppercase tracking-wide text-stone-500 text-sm',
            )}
            >
              Wöchentlich
            </div>

            <div className="text-right mr-1">
              {bill.subscription.numberOfMeals}
              {' Gerichte à '}
              {formatEuroCents(bill.pricePerMealCent)}
            </div>
            <div className="text-right border-r-8 border-r-transparent">
              {formatEuroCents(bill.subscription.mealPriceTotalCent)}
            </div>
            <div />

            {bill.subscription.discountTotalCent > 0
                  && (
                  <>
                    <div className="text-right mr-1">MARKTKOST Rabatt</div>
                    <div className="text-right border-r-8 border-r-transparent">
                      {formatEuroCents(-bill.subscription.discountTotalCent)}
                    </div>
                    <div />
                  </>
                  )}
            {bill.subscription.sponsoringTotalCent > 0
                  && (
                  <>
                    <div className="text-right mr-1">
                      Arbeitgeberzuschuss
                    </div>
                    <div className="text-right border-r-8 border-r-transparent">
                      {formatEuroCents(-bill.subscription.sponsoringTotalCent)}
                    </div>
                    <div />
                  </>
                  )}

            <div className="col-span-3 border-t h-1 border-stone-500 border-opacity-30" />

            <div className="text-right mr-1" />
            <div className="text-right border-r-8 border-r-transparent">
              {formatEuroCents(bill.subscription.totalCent)}
            </div>
            <div />

            <div className={classNames(
              'col-span-3',
              'mb-1 mr-4',
              'font-title uppercase tracking-wide text-stone-500 text-sm',
            )}
            >
              {formatGermanDate(bill.startDate)}
            </div>

            <div className="text-right mr-1">
              {bill.additional.numberOfMeals}
              {' Gerichte à '}
              {formatEuroCents(bill.pricePerMealCent)}
            </div>
            <div className="text-right border-r-8 border-r-transparent">
              {formatEuroCents(bill.additional.mealPriceTotalCent)}
            </div>
            <div />

            {bill.additional.discountTotalCent > 0
                  && (
                  <>
                    <div className="text-right mr-1">MARKTKOST Rabatt</div>
                    <div className="text-right border-r-8 border-r-transparent">
                      {formatEuroCents(-bill.additional.discountTotalCent)}
                    </div>
                    <div />
                  </>
                  )}
            {bill.additional.sponsoringTotalCent > 0
                  && (
                  <>
                    <div className="text-right mr-1">
                      Arbeitgeberzuschuss
                    </div>
                    <div className="text-right border-r-8 border-r-transparent">
                      {formatEuroCents(-bill.additional.sponsoringTotalCent)}
                    </div>
                    <div />
                  </>
                  )}

            <div className="col-span-3 border-t h-1 border-stone-500 border-opacity-30" />

            <div className="text-right mr-1" />
            <div className="text-right bg-mk-light rounded-l-lg border-r-8 border-r-mk-light font-bold">
              {formatEuroCents(bill.additional.totalCent)}
            </div>
            <div className="bg-mk-light rounded-r-lg font-bold">zusätzlich</div>

            <div className="text-right mr-1 mt-4">gesamt</div>
            <div
              className="text-right mt-4 border-r-8 border-r-transparent"
            >
              {formatEuroCents(bill.total.totalCent)}
            </div>

          </div>
        </div>
      </>
    );
  }
  return null;
}
