import { Modal } from '@/components/base/modal';
import { fetchStripeSetupIntent, PaymentMethod } from '@/backend/api/api-service';
import classNames from 'classnames';

import { PaypalButton } from '@/components/settings/paypal-button';
import { StripeButton } from '@/components/base/stripe-button';

function paymentMethodText(paymentMethod?: PaymentMethod) {
  if (paymentMethod?.type === 'braintree-paypal') {
    return (
      <span>
        PayPal (
        {paymentMethod?.description}
        )
      </span>
    );
  }

  if (paymentMethod?.type === 'stripe-sepa') {
    return (
      <span>
        SEPA Lastschrift (IBAN endet auf
        {' '}
        {paymentMethod?.description}
        )
      </span>
    );
  }

  if (paymentMethod?.type === 'stripe-cc') {
    return (
      <span>
        Kreditkarte (endet auf
        {' '}
        {paymentMethod?.description}
        )
      </span>
    );
  }

  if (paymentMethod) {
    return (
      <span>{paymentMethod.type}</span>
    );
  }

  return <span />;
}

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onCancel: () => void;
  onDone: () => void;
  paymentMethod: PaymentMethod | undefined;
  redirectQuery: string;
}

export function SettingsPaymentDialog({
  isOpen, paymentMethod, onCancel, onDone, setIsOpen, redirectQuery,
}: Props) {
  return (
    <Modal isOpen={isOpen} testId="settings-payment-dialog">
      <div className="text-lg font-title uppercase tracking-wide font-semibold bg-mk rounded-t-2xl p-3">
        Zahlungsmethode
      </div>
      <div className="bg-white rounded-b-xl">
        <div className={classNames('mt-6 flex flex-col gap-4 p-3')}>
          {!!paymentMethod && (
            <button
              type="button"
              className={classNames(
                'border border-stone-500 px-6 py-3 rounded-xl',
                'text-md text-stone-500 font-title font-semibold uppercase',
              )}
              onClick={onCancel}
            >
              {paymentMethodText(paymentMethod)}
              {' '}
              Beibehalten
            </button>
          )}
          <StripeButton
            stripePaymentMethod="card"
            buttonLabel="Neue Kreditkarte"
            dialogTitle="Kreditkarte"
            returnPath={`stripe?redirect=${redirectQuery}`}
            fetchSetupIntent={fetchStripeSetupIntent}
          />
          <StripeButton
            stripePaymentMethod="sepa_debit"
            buttonLabel="Neue SEPA Lastschrift"
            dialogTitle="SEPA Lastschrift"
            returnPath={`stripe?redirect=${redirectQuery}`}
            fetchSetupIntent={fetchStripeSetupIntent}
          />
          <PaypalButton onDone={onDone} />
          {/* TODO make visible but disabled or loading state, or at least reserve height */}
          <div className="flex mt-6">
            <button
              type="button"
              className={classNames(
                'border border-mk-900 px-6 py-3 rounded-xl',
                'text-md text-mk-900 font-title font-semibold uppercase',
              )}
              onClick={() => setIsOpen(false)}
            >
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
