import {
  DateTimeFormatter, LocalDate, nativeJs, ZoneId,
} from '@js-joda/core';
import '@js-joda/timezone';
import { Locale } from '@js-joda/locale_de';

export function localDateFromNativeJsDate(date: Date, zoneId: ZoneId = ZoneId.of('Europe/Berlin')): LocalDate {
  return LocalDate.from(nativeJs(date, zoneId));
}

const germanDateFormatter = DateTimeFormatter.ofPattern('dd.MM.yyyy').withLocale(Locale.GERMAN);
export function formatGermanDate(date: LocalDate | Date | string) {
  if (date instanceof LocalDate) {
    return date.format(germanDateFormatter);
  }
  if (date instanceof Date) {
    return localDateFromNativeJsDate(date).format(germanDateFormatter);
  }

  return LocalDate.parse(date).format(germanDateFormatter);
}
