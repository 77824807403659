import { Week } from '@/backend/api/api-service';
import { ProductOrder } from '@/components/product/product-order';

interface Props {
  week: Week | null;
  order: Map<string, number>;
  updateOrder: (order: Map<string, number>) => void;
}

export function ActiveWeekOrder({
  week, order, updateOrder,
}: Props) {
  return (week
        && (
        <section>
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3 mb-2">
            { week.products.map((product) => (
              <ProductOrder
                item={product}
                userQuantity={order.get(product.productId) || 0}
                setUserQuantity={(n: number) => {
                  const newLocalOrder = new Map(order.set(product.productId, n));
                  updateOrder(newLocalOrder);
                }}
                disabled={false}
                key={product.productId}
              />
            ))}
          </div>
        </section>
        )
  );
}
